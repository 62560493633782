import Button from '@/components/buttons/Button'
import _get from 'lodash/get'
import { ballotStore } from '@/services/Ballot/store'
import { parseDate } from '@/utilities/Date/parse'
import moment from 'moment'

export const methods = {
  determineRows: function(list) {
    const dateFormat = 'LL'

    let filteredList = list

    var rows = filteredList.map(entry => {
      let ballotType = _get(entry, 'ballotType', '')
      const electionType = _get(entry, 'electionVotingIsCumulative', false)

      if (ballotType && ballotType === 'election') {
        if (electionType === true) {
          ballotType = `${ballotType} (cumulative)`
        } else {
          ballotType = `${ballotType} (non-cumulative)`
        }
      }

      const description = _get(entry, 'description', '')
      const submittedBallots = _get(entry, 'submittedBallotCount', 0)
      const tempCompleteBy = _get(entry, 'completeByDate', null)
      const completeByDate = tempCompleteBy ? parseDate(tempCompleteBy, dateFormat) : null
      const tempPublishDate = _get(entry, 'publishedDate', moment('01/01/1900').format(dateFormat))
      const publishedDate = tempPublishDate
        ? parseDate(tempPublishDate, dateFormat)
        : moment('01/01/1900').format(dateFormat)

      return {
        description,
        ballotType,
        submittedBallots,
        completeByDate: {
          date: completeByDate,
          format: dateFormat
        },
        publishedDate: {
          date: publishedDate,
          format: dateFormat
        },
        details: {
          component: Button,
          props: {
            onClick: () => {
              this.processDetail(entry)
            },
            text: 'Details'
          }
        },
        erase: {
          component: Button,
          props: {
            onClick: () => {
              this.confirmDeleteBallot(entry)
            },
            text: 'Delete'
          }
        }
      }
    })

    this.rows = rows
  },

  redirectToDetails(ballot) {
    console.debug('in redirectToDetails=' + JSON.stringify(ballot))
    this.processDetail(ballot)
  },

  forProperDateSort(date) {
    const dateFormat = 'LL'

    try {
      if (date) {
        return moment(date).format(dateFormat)
      } else {
        return null
      }
    } catch (exception) {
      console.debug(exception)
      return null
    }
  },

  async confirmDeleteBallot(entry) {
    this.$buefy.dialog.confirm({
      title: 'Deleting Ballot',
      message: `Are you sure you want to <b>delete</b> this ballot, "${entry.description}"?`,
      confirmText: 'Delete Ballot',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: async () => await this.deleteBallot(entry.ballotID)
    })
  },

  async deleteBallot(ballotID) {
    try {
      await ballotStore.dispatch('deleteBallot', {
        ballotID,
        done: () => {
          this.loading = false
          this.reload()
        }
      })
    } catch (e) {
      console.debug('There was an issue deleting your ballot.' + e)
    }
  },

  async reload() {
    console.debug('in reload...')
    this.loading = true

    await ballotStore
      .dispatch('getBallotList', {
        hoaID: this.hoaId,
        publishedAndCompleteBy: false,
        unitID: null
      })
      .then(({ list }) => {
        this.determineRows(list)
        this.loading = false
      })
  },

  processDetail(ballot) {
    if (ballot) {
      const path = `/evotingTabs/${ballot.ballotID}/evotingDetails#tab`
      this.$router.push(path)
    }
  },

  createBallotModal() {
    this.toggle = true
    this.selectedBallot = null
  }
}
